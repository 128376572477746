<template>
  <div class="dabox">
    <!-- 文字标识 -->
    <div class="dingwei1">
      <p class="wenzi wenzi1">自换热废水</p>
      <p class="wenzi wenzi2">自反应釜废水</p>
      <p class="wenzi wenzi3">至排空总管</p>
      <p class="wenzi wenzi4">自软水装置</p>
      <p class="wenzi wenzi5">自精馏蒸汽疏水</p>
      <p class="wenzi wenzi6">自四容水槽废水</p>
      <p class="wenzi wenzi7">自四容水槽废水</p>
      <p class="wenzi wenzi8">自精馏废水</p>
      <p class="wenzi wenzi9">取样口</p>
      <p class="wenzi wenzi10">去废水池</p>
      <p class="wenzi wenzi11">自储气罐</p>
      <p class="wenzi wenzi12">取水口</p>
      <p class="famen famen1">HV010</p>
      <p class="famen famen2">HV011</p>
      <p class="famen famen3">HV008</p>
      <p class="famen famen4">SV004</p>
      <p class="famen famen5">HV009</p>
      <p class="famen famen6">HV005</p>
      <p
        class="shuzi shuzi1"
        @click="toCompon(2, 'TI002_DCS', 'DB1S', 'TI002', '公用废水箱温度')"
        @dblclick="
          Cclick(infoList.DB1S.TI002_DCS, 'TI002_DCS', 'DB1S', 'TI002')
        "
      >
        {{ infoList.DB1S.TI002_DCS }} ℃
      </p>
      <p
        class="shuzi shuzi2"
        @click="toCompon(2, 'LI002_DCS', 'DB1S', 'LI002', '公用废水箱液位')"
        @dblclick="
          Cclick(infoList.DB1S.LI002_DCS, 'LI002_DCS', 'DB1S', 'LI002')
        "
      >
        {{ infoList.DB1S.LI002_DCS }} %
      </p>
      <p
        class="shuzi fen shuzi3"
        @click="
          toCompon(2, 'AN96', 'DB1V', 'AN96_JLT', '公用工程废水罐体积软测量')
        "
        @dblclick="Cclick(infoList.DB1V.AN96, 'AN96', 'DB1V', 'AN96_JLT')"
      >
        {{ infoList.DB1V.AN96 }} L
      </p>
      <p
        class="shuzi shuzi4"
        @click="toCompon(2, 'BP002_DCS', 'DB1S', 'BP002', '公用废水泵频率')"
        @dblclick="
          Cclick(infoList.DB1S.BP002_DCS, 'BP002_DCS', 'DB1S', 'BP002')
        "
      >
        {{ infoList.DB1S.BP002_DCS }} %
      </p>
      <p
        class="shuzi shuzi5"
        @click="toCompon(2, 'BP001_DCS', 'DB1S', 'BP001', '公用水泵频率')"
        @dblclick="
          Cclick(infoList.DB1S.BP001_DCS, 'BP001_DCS', 'DB1S', 'BP001')
        "
      >
        {{ infoList.DB1S.BP001_DCS }} %
      </p>
      <div class="rulie rulie1">{{infoList.DB1V.DG112 ? '解列': '入列'}}</div>
      <div class="kaiguan kaiguan1">
        <img :src="infoList.DB1S.P002_C | tyFifter" alt="" />
      </div>
      <!-- <div class="sv sv1">
        <img :src="infoList.DB1S.SV004_C | tyFifter" alt="" />
      </div> -->
    </div>
    <div class="dingwei2">
      <p class="wenzi wenzi13">去换热装置</p>
      <p class="wenzi wenzi14">去四容水槽</p>
      <p class="wenzi wenzi15">去精馏装置</p>
      <p class="wenzi wenzi16">去反应釜装置</p>
      <p class="wenzi wenzi17">泵出口压力</p>
      <p class="wenzi wenzi18">进水</p>
      <p class="wenzi wenzi19">去公用废水罐</p>
      <p class="famen famen7">HV006</p>
      <p class="famen famen8">HV007</p>
      <p class="famen famen9">HV013</p>
      <p class="famen famen10">SV003</p>
      <p class="famen famen11">HV015</p>
      <p class="famen famen12">HV014</p>
      <p class="famen famen13">HV012</p>
      <p class="famen famen14">SV002</p>
      <p
        class="shuzi shuzi6"
        @click="toCompon(2, 'PI302', 'DB1S', 'PI302', '水泵出口压力')"
        @dblclick="Cclick(infoList.DB1S.PI302, 'PI302', 'DB1S', 'PI302')"
      >
        {{ infoList.DB1S.PI302 }} Kpa
      </p>
      <p
        class="shuzi shuzi7"
        @click="toCompon(2, 'TI001_DCS', 'DB1S', 'TI001', '公用水箱温度')"
        @dblclick="
          Cclick(infoList.DB1S.TI001_DCS, 'TI001_DCS', 'DB1S', 'TI001')
        "
      >
        {{ infoList.DB1S.TI001_DCS }} ℃
      </p>
      <p
        class="shuzi shuzi8"
        @click="toCompon(2, 'LI001_DCS', 'DB1S', 'LI001', '公用水箱液位')"
        @dblclick="
          Cclick(infoList.DB1S.LI001_DCS, 'LI001_DCS', 'DB1S', 'LI001')
        "
      >
        {{ infoList.DB1S.LI001_DCS }} %
      </p>
      <p
        class="shuzi fen shuzi9"
        @click="
          toCompon(2, 'AN94', 'DB1V', 'AN94_JLT', '公用工程供水罐体积软测量')
        "
        @dblclick="Cclick(infoList.DB1V.AN94, 'AN94', 'DB1V', 'AN94_JLT')"
      >
        {{ infoList.DB1V.AN94 }} L
      </p>
      <div class="rulie rulie2">{{infoList.DB1V.DG111 ? '解列': '入列'}}</div>
      <div class="rulie rulie3">{{infoList.DB1V.DG161 ? '解列': '入列'}}</div>
      <div class="rulie rulie4">{{infoList.DB1V.DG113 ? '解列': '入列'}}</div>
      <div class="rulie rulie5">{{infoList.DB1V.DG115 ? '解列': '入列'}}</div>
      <div class="kaiguan kaiguan2">
        <img :src="infoList.DB1V.AN337 | tyFifter" alt="" />
      </div>
      <div class="kaiguan kaiguan3">
        <img :src="infoList.DB1S.X001_C | tyFifter" alt="" />
      </div>
      <!-- <div class="sv sv2">
        <img :src="infoList.DB1S.SV003_C | tyFifter" alt="" />
      </div>
      <div class="sv sv3">
        <img :src="infoList.DB1S.SV002_C | tyFifter" alt="" />
      </div> -->
    </div>
    <div class="dingwei3">
      <p class="wenzi wenzi20">至排空总管</p>
      <p class="wenzi wenzi21">排污口</p>
      <p class="wenzi wenzi22">至供气总管</p>
      <p class="wenzi wenzi23">排污口</p>
      <p class="famen famen15">HV003</p>
      <p class="famen famen16">BP002</p>
      <p class="famen famen17">SV001</p>
      <p class="famen famen18">HV021</p>
      <p class="famen famen19">HV016</p>
      <p class="famen famen20">HV017</p>
      <p class="famen famen21">HV019</p>
      <p class="famen famen22">HV020</p>
      <p class="famen famen23">HV018</p>
      <!-- <div class="sv sv4">
        <img :src="infoList.DB1S.SV001_C | tyFifter" alt="" />
      </div> -->
      <p
        class="shuzi shuzi10"
        @click="toCompon(2, 'PI001_DCS', 'DB1S', 'PI001', '压缩空气压力')"
        @dblclick="
          Cclick(infoList.DB1S.PI001_DCS, 'PI001_DCS', 'DB1S', 'PI001')
        "
      >
        {{ infoList.DB1S.PI001_DCS }} MPa
      </p>
      <div class="rulie rulie6">{{infoList.DB1V.DG110 ? '解列': '入列'}}</div>
      <div class="rulie rulie7">{{infoList.DB1V.DG114 ? '解列': '入列'}}</div>
      <div class="kaiguan kaiguan4">
        <img :src="infoList.DB1S.C001_C | tyFifter" alt="" />
      </div>
    </div>
    <div class="dingwei4">
      <p class="wenzi wenzi24">至放空管</p>
      <p class="wenzi wenzi25">蒸汽温度</p>
      <p class="wenzi wenzi26">备用</p>
      <p class="wenzi wenzi27">至精馏、反应釜、传热单元</p>
      <p class="wenzi wenzi28">蒸汽压力</p>
      <p class="wenzi wenzi29">排污</p>
      <p class="wenzi wenzi30">泵出口压力</p>
      <p class="wenzi wenzi31">自公共水箱</p>
      <p class="famen famen24">BP001</p>
      <p class="famen famen25">HV001</p>
      <p class="famen famen26">HV30702</p>
      <p class="famen famen27">SV302</p>
      <p class="famen famen28">HV30602</p>
      <p class="famen famen29">HV30601</p>
      <p class="famen famen30">HV310</p>
      <p class="famen famen31">HV309</p>
      <p class="famen famen32">HV344</p>
      <!-- <div class="sv sv5">
        <img :src="infoList.DB1S.SV303_C | tyFifter" alt="" />
      </div> -->
      <p
        class="shuzi shuzi11"
        @click="toCompon(2, 'TI314', 'DB1S', 'TI314', '分汽包蒸汽出口温度')"
        @dblclick="Cclick(infoList.DB1S.TI314, 'TI314', 'DB1S', 'TI314')"
      >
        {{ infoList.DB1S.TI314 }} ℃
      </p>
      <p
        class="shuzi shuzi12"
        @click="toCompon(2, 'PI305', 'DB1S', 'PI305', '分汽包压力')"
        @dblclick="Cclick(infoList.DB1S.PI305, 'PI305', 'DB1S', 'PI305')"
      >
        {{ infoList.DB1S.PI305 }} Kpa
      </p>
      <p
        class="shuzi shuzi13"
        @click="toCompon(2, 'PI302', 'DB1S', 'PI302', '水泵出口压力')"
        @dblclick="Cclick(infoList.DB1S.PI302, 'PI302', 'DB1S', 'PI302')"
      >
        {{ infoList.DB1S.PI302 }} Kpa
      </p>
      <p
        class="shuzi shuzi14"
        @click="toCompon(2, 'FIC401', 'DB1S', 'FIC401', '锅炉进水流量')"
        @dblclick="Cclick(infoList.DB1S.FIC401, 'FIC401', 'DB1S', 'FIC401')"
      >
        {{ infoList.DB1S.FIC401 }} L/h
      </p>
      <div class="rulie rulie8">{{infoList.DB1V.DG142 ? '解列': '入列'}}</div>
      <div class="rulie rulie9">{{infoList.DB1V.DG142 ? '解列': '入列'}}</div>
      <div class="kaiguan kaiguan5">
        <img :src="infoList.DB1S.V104_C | tyFifter" alt="" />
      </div>
    </div>
    <Historical
      v-if="isHshow"
      @sendStatus="isClose"
      :historyname="historyname"
      :node="nodename"
      :Lkname="Aname"
      :chName="chName"
      :infoList="infoList"
    ></Historical>
    <Manual
      :key="isIndex"
      v-if="isMshow"
      @sendStatus="isClose"
      :historyname="Manualname"
      :node="Manualnode"
      :Lkname="ManualAname"
      :titname="Manualtitname"
      :infoList="infoList"
    ></Manual>
  </div>
</template>
<script>
import index from "./_index/index.js";
import Manual from "@/components/Manual.vue"; //手操器组件
//import Evaluate from "@/components/Evaluate.vue"; //运行评价组件
//import Security from "@/components/Security.vue"; //安全设置组件
import Firstcontrol from "@/components/Firstcontrol.vue"; //先控画面组件
import Historical from "@/components/Historical.vue"; //历史趋势
export default {
  name: "d6pub",
  components: {
    Manual,
    Firstcontrol,
    Historical,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  data: () => {
    return {
      chName: "",
      maskbok: false,
      JRLcysz: false,
      isMshow: false,
      isHshow: false,
      Aname: "",
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      canshu1: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      flag: 0,
      isComShow: false,
      fullscreenLoading: false,
      grouptime: null,
      spotArr: [],
      projectData: "",
    };
  },
  mixins: [index],
  created() {
    this.spotArr = JSON.parse(localStorage.getItem("spotArr"))
      ? JSON.parse(localStorage.getItem("spotArr"))
      : [];
    this.authInfo = JSON.parse(localStorage.getItem("autharr"));
    this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"));
  },
  watch: {
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },

      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  methods: {
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
  },
  filters: {
    //   // 阀门红绿灯
    //   typeFifter: (val) => {
    //     if (val < 5) {
    //       return require("@/assets/images/截止阀.png");
    //     } else if (val >= 5) {
    //       return require("@/assets/images/截止阀2.png");
    //     }
    //   },
    //釜内红绿灯
    tyFifter: (val) => {
      if (val == false) {
        return require("@/assets/images/关闭.png");
      } else if (val == true) {
        return require("@/assets/images/开始.png");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.dabox {
  box-sizing: border-box;
  width: 85%;
  height: 82vh;
  margin-left: 7vw;
  margin-top: 2vh;
  background-image: url("~@/assets/images/公用系统.png");
  background-size: 100% 100%;
  display: flex;
  flex-wrap: wrap;
  .dingwei1,
  .dingwei2,
  .dingwei3,
  .dingwei4 {
    position: relative;
    font-size: 0.7vw;
    width: 49.8%;
    height: 50%;
    // border: 1px solid #fff;
    .famen {
      color: #fff;
      font-size: 0.7vw;
      position: absolute;
    }
    .wenzi {
      // font-size: 0.7vw;
      color: #fff;
      position: absolute;
    }
    .shuzi {
      position: absolute;
      // font-size: 0.7vw;
      color: #fff;
    }
    .fen {
      color: #ff09db;
    }
    .rulie {
      position: absolute;
      color: #000;
      background-color: #fff;
      cursor: pointer; /* 指针形状 */
      width: 2vw;
      height: 2vh;
      font-size: 0.7vw;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .sv {
      position: absolute;
      width: 0.7vw;
      // height: 0.5vh;
    }
    .sv1 {
      bottom: 17vh;
      right: 5vw;
    }
    .sv2 {
      top: 0vh;
      right: 15.3vw;
    }
    .sv3 {
      top: 17.5vh;
      left: 17.4vw;
    }
    .sv4 {
      top: 13.2vh;
      left: 11.8vw;
    }
    .sv5 {
      top: 6.4vh;
      right: 5.6vw;
    }
    .kaiguan {
      position: absolute;
    }
    .kaiguan1 {
      bottom: -0.5vh;
      right: 14.5vw;
    }
    .kaiguan2 {
      bottom: -0.5vh;
      left: 1vw;
    }
    .kaiguan3 {
      top: 12vh;
      right: 6.5vw;
    }
    .kaiguan4 {
      bottom: 5.3vh;
      left: 0.5vw;
    }
    .kaiguan5 {
      bottom: 9.5vh;
      left: 20.5vw;
    }
    .wenzi1 {
      top: 5.1vh;
      left: -1vw;
    }
    .wenzi2 {
      top: 7.7vh;
      left: -1.7vw;
    }
    .wenzi3 {
      top: 10.3vh;
      left: -1vw;
    }
    .wenzi4 {
      top: 12.9vh;
      left: -1vw;
    }
    .wenzi5 {
      top: 15.1vh;
      left: -2.4vw;
    }
    .wenzi6 {
      top: 17.7vh;
      left: -2.4vw;
    }
    .wenzi7 {
      top: 20.1vh;
      left: -2.4vw;
    }
    .wenzi8 {
      top: 22.2vh;
      left: -1vw;
    }
    .wenzi9 {
      top: 24.2vh;
      left: 0.4vw;
    }
    .wenzi10 {
      top: 4.5vh;
      right: 16vw;
    }
    .wenzi11 {
      top: 3vh;
      right: 7.3vw;
    }
    .wenzi12 {
      bottom: 9vh;
      right: 5.7vw;
    }
    .famen1 {
      top: 17.5vh;
      right: 9.4vw;
    }
    .famen2 {
      top: 17.5vh;
      right: 4.5vw;
    }
    .famen3 {
      top: 20.5vh;
      right: 14vw;
    }
    .famen4 {
      bottom: 16.5vh;
      right: 2.5vw;
    }
    .famen5 {
      bottom: 13.5vh;
      right: 9.1vw;
    }
    .famen6 {
      bottom: 7.3vh;
      right: 2.1vw;
    }
    .shuzi1 {
      bottom: 9.5vh;
      left: 5vw;
    }
    .shuzi2 {
      bottom: 2.5vh;
      left: 5vw;
    }
    .shuzi3 {
      bottom: 0vh;
      left: 5vw;
    }
    .shuzi4 {
      bottom: 2.3vh;
      right: 9.5vw;
    }
    .shuzi5 {
      bottom: 2.3vh;
      right: 2vw;
    }
    .rulie1 {
      bottom: 3.5vh;
      right: 14vw;
    }
    .wenzi13 {
      top: -0.7vh;
      left: 7vw;
    }
    .wenzi14 {
      top: 2.1vh;
      left: 7vw;
    }
    .wenzi15 {
      top: 4.6vh;
      left: 7vw;
    }
    .wenzi16 {
      top: 7.3vh;
      left: 7vw;
    }
    .wenzi17 {
      top: 10.3vh;
      left: 0vw;
    }
    .wenzi18 {
      top: 1vh;
      left: 20vw;
    }
    .wenzi19 {
      top: 5vh;
      right: 14.5vw;
    }
    .famen7 {
      top: 20.3vh;
      left: 0vw;
    }
    .famen8 {
      top: 19.5vh;
      left: 3.7vw;
    }
    .famen9 {
      top: 10.3vh;
      left: 10.8vw;
    }
    .famen10 {
      top: -2.4vh;
      right: 14.6vw;
    }
    .famen11 {
      top: 2.5vh;
      right: 8.4vw;
    }
    .famen12 {
      top: 6.2vh;
      left: 18.5vw;
    }
    .famen13 {
      top: 10.3vh;
      left: 16.8vw;
    }
    .famen14 {
      top: 17.5vh;
      left: 18.5vw;
    }
    .shuzi6 {
      top: 12.3vh;
      left: 0vw;
    }
    .shuzi7 {
      bottom: 9.5vh;
      left: 18.5vw;
    }
    .shuzi8 {
      bottom: 2.5vh;
      left: 18.5vw;
    }
    .shuzi9 {
      bottom: 0vh;
      left: 18.5vw;
    }
    .rulie2 {
      bottom: 4vh;
      left: 0.5vw;
    }
    .rulie3 {
      top: 0vh;
      right: 17vw;
    }
    .rulie4 {
      top: 12vh;
      right: 13vw;
    }
    .rulie5 {
      top: 22vh;
      right: 23.3vw;
    }
    .wenzi20 {
      top: 8.3vh;
      left: 0vw;
    }
    .wenzi21 {
      bottom: 3.4vh;
      left: 20vw;
    }
    .wenzi23 {
      bottom: 3vh;
      right: 0vw;
    }
    .wenzi22 {
      top: 6.5vh;
      right: 7vw;
    }
    .famen15 {
      top: -0.5vh;
      right: 18.7vw;
    }
    .famen16 {
      top: 2.5vh;
      right: 13.5vw;
    }
    .famen17 {
      top: 13vh;
      left: 9vw;
    }
    .famen18 {
      top: 13vh;
      left: 17.5vw;
    }
    .famen19 {
      bottom: 11.8vh;
      left: 13.2vw;
    }
    .famen20 {
      bottom: 1.7vh;
      left: 13.2vw;
    }
    .famen21 {
      bottom: 15vh;
      left: 20vw;
    }
    .famen23 {
      bottom: 1.5vh;
      right: 4.7vw;
    }
    .famen22 {
      top: 12.2vh;
      right: 7.3vw;
    }
    .shuzi10 {
      bottom: 15vh;
      right: 4vw;
    }
    .rulie6 {
      bottom: 13vh;
      left: 0vw;
    }
    .rulie7 {
      top: 11vh;
      left: 10.5vw;
    }
    .wenzi24 {
      top: -1.5vh;
      right: 0vw;
    }
    .wenzi25 {
      top: 10.8vh;
      right: 15vw;
    }
    .wenzi26 {
      top: 10.5vh;
      right: 20vw;
    }
    .wenzi27 {
      top: 18vh;
      right: 0vw;
    }
    .wenzi28 {
      bottom: 9.5vh;
      right: 8vw;
    }
    .wenzi29 {
      bottom: 2vh;
      right: 11vw;
    }
    .wenzi30 {
      bottom: 11vh;
      left: 9vw;
    }
    .wenzi31 {
      bottom: 4vh;
      left: 2.5vw;
    }
    .famen24 {
      top: 2.5vh;
      left: 0vw;
    }
    .famen25 {
      top: -0.3vh;
      left: 4.8vw;
    }
    .famen26 {
      top: 5vh;
      right: 11vw;
    }
    .famen27 {
      top: 4.5vh;
      right: 4.5vw;
    }
    .famen28 {
      top: 15.7vh;
      right: 21.3vw;
    }
    .famen29 {
      bottom: 14.8vh;
      right: 15vw;
    }
    .famen30 {
      bottom: 5vh;
      right: 15vw;
    }
    .famen31 {
      bottom: 5vh;
      left: 14.5vw;
    }
    .famen32 {
      bottom: 5vh;
      left: 6.7vw;
    }
    .shuzi11 {
      top: 13.3vh;
      right: 15vw;
    }
    .shuzi12 {
      bottom: 7.5vh;
      right: 8vw;
    }
    .shuzi13 {
      bottom: 9vh;
      left: 9vw;
    }
    .shuzi14 {
      bottom: 7vh;
      left: 9vw;
    }
    .rulie8 {
      top: 2.5vh;
      right: 4.5vw;
    }
    .rulie9 {
      bottom: 9vh;
      right: 15vw;
    }
  }
}
</style>